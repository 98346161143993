import React from 'react'
import "./Header.css"
import Book from "../../assets/3d.png"

const Header = () => {
    return (
        <div className='Header' id='home'>
            <div>
                <div>
                    <h1>The TransBeing Experience</h1>
                    <h4>Take a journey to Awaken, Transform, and Thrive.</h4>
                    <p>Then, reach the ultimate freedom of DECLARING GENDER IDENTITY INDEPENDENCE.</p>
                    <a href='#contact'>Contact me</a>
                </div>
            </div>
            {/* <img className='d-none d-lg-block' src={Book} alt="Book" /> */}
        </div>
    )
}

export default Header
