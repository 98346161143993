import { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import About from "./components/About/About";
import Blog from "./components/Blog/Blog";
import ContactUs from "./components/ContactUs/ContactUs";
import Events from "./components/Events/Events";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Sidebar from "./components/Sidebar/Sidebar";
// import component 👇
import Drawer from 'react-modern-drawer'

//import styles 👇
import 'react-modern-drawer/dist/index.css'

function App() {
  console.log(process.env.REACT_APP_BACKEND_URL);
  const [isOpen, setIsOpen] = useState(false)
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState)
  }

  return (
    <div className="App">
      <BrowserRouter>
        <div className="d-flex gap-0">
          <div className="leftMain position-relative">
            <div className="sidebarContainer">
              <Sidebar />
            </div>
            <button className="hamburger btn" id={isOpen && "moveIt"} onClick={toggleDrawer}>
              <i className="fa fa-navicon tx-size-28"></i>
            </button>
            <Drawer
              open={isOpen}
              onClose={toggleDrawer}
              direction='left'
              className='bla bla bla'
            >
              <Sidebar toggleDrawer={toggleDrawer} />
            </Drawer>
          </div>
          <div className="rightMain">
            <Header />
            <About />
            <Events />
            <Blog />
            <ContactUs />
            <Footer />
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
