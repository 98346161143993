import React from 'react'
import Author from "../../assets/author.png"
import "./About.css"

const About = () => {
  return (
    <div className='About' id='about'>
      <div className='intro'>
        <h2>
          Declaring Gender Identity Independence is thinking beyond
          limitation and living authentically
        </h2>
      </div>
      <div className="team">
        <div className='teamHeader'>
          <h2>About</h2>
          <p>The book and the author</p>
        </div>
        <div className='teamRow'>
          <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className='mx-5'>
                  <h2>The Tension</h2>
                  <p className='mt-4'>
                    External bias factors
                    such as family, peers, society, and healthcare chip away at self-esteem, causing fear and doubt about our worth as human beings and our true selves.
                  </p>
                </div>
              </div>
              <div className="carousel-item">
                <div className='mx-5'>
                  <h2>The Opportunity</h2>
                  <p className='mt-4'>
                    To break free from the invisible barriers and declare gender identity and expression independence. Become authentic mentally and physically.
                  </p>
                </div>
              </div>
              <div className="carousel-item">
                <div className='mx-5'>
                  <h2>The Path Forward</h2>
                  <p className='mt-4'>
                    Dreaming, Becoming,
                    to Living:
                    A life with purpose, thriving in uncertainty to create a balanced lifestyle of a TransBeing.
                  </p>
                </div>
              </div>
            </div>
            <button className="carousel-control-prev w-auto" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next w-auto" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
          {/* <div className='indRow'>
            <div>
              <h2>The Tension</h2>
              <p>
                External bias factors
                such as family, peers, society, and healthcare chip away at self-esteem, causing fear and doubt about our worth as human beings and our true selves.
              </p>
            </div>
            <div>
              <h2>The Opportunity</h2>
              <p>
                To break free from the invisible barriers and declare gender identity and expression independence. Become authentic mentally and physically.
              </p>
            </div>
            <div>
              <h2>The Path Forward</h2>
              <p>
                Dreaming, Becoming,
                to Living:
                A life with purpose, thriving in uncertainty to create a balanced lifestyle of a TransBeing.
              </p>
            </div>
          </div> */}
          <div className='centeredStatements'>
            <ul>
              <li>Discover your deeper self and life purpose  </li>
              <li>Create and follow a personalized transition plan</li>
              <li>Stretch yourself to grow with preparation lessons after each chapter</li>
            </ul>
          </div>
          <div className='board'>
            <h1>THE AUTHOR</h1>
            <p>Meet the award winning thinker and extreme creator.</p>
            <div className='author'>
              <div>
                <img src={Author} alt="Author" />
                <h3 className='text-center mt-3'>Denin Greene</h3>
              </div>
              <div>
                <h3>Meet the Creator, Olympian, and entrepreneur.</h3>
                <p>Denin Greene is a two-time Olympian athlete in Track and Field and a Hall of Fame inductee. Throughout his life, learning to live to the fullest and persevere through challenges has led to success and authenticity. Acutely aware of his athletic abilities, Denin also had an instinctual understanding of gender identity. Denin’s most challenging and rewarding journey was taking action towards becoming his true self.</p>
                <p>Documenting the eight years of his experiences, Denin has envisioned an approach that the gender-affirming community can follow and relate to at every stage. His keen insights into evolving gender will undoubtedly lead to medical breakthroughs soon. Delivering an inspirational and actionable message, Denin shares and reflects on his time spent as a world-class athlete, working in a corporate environment, and overcoming fear and discrimination.</p>
                <p>Denin is on a mission to promote the evolution of people and the medical industry while advocating the enhancement of our physical, psychological, social, and spiritual selves.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
