import React, { useState } from 'react'
import "./ContactUs.css"
import axios from "axios"

const ContactUs = () => {
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [message, setMessage] = useState("");

  const submitHandler = async () => {
    await axios.post("/send-mail", { email, fullName, message }).then(res => {
      if (res.status === 200) {
        alert("Email sent successfully");
      } else {
        console.log("Error");
        alert("Email could not be sent")
      }
    })
  }

  return (
    <div className='ContactUs' id='contact'>
      <div className='contactUsHeader'>
        <h2>Contact Me</h2>
        <p>Get in touch.</p>
      </div>
      <div className='contactUsInner row gx-5 gy-5'>
        <div className='left col-12 col-md-8'>
          <h2>WRITE YOUR MESSAGE</h2>
          <div className='row gy-3'>
            <div className='col-12 mt-5'>
              <h4>I’m interested in:</h4>
              <div className='mt-4 d-flex justify-content-between flex-wrap gap-3 align-items-center'>
                <div className='d-flex gap-2'>
                  <input type="checkbox" />
                  <span>Additional information</span>
                </div>
                <div className='d-flex gap-2'>
                  <input type="checkbox" />
                  <span>The pre-sale list - add me</span>
                </div>
                <div className='d-flex gap-2'>
                  <input type="checkbox" />
                  <span>Subscribing to updates</span>
                </div>
                <div className='d-flex gap-2'>
                  <input type="checkbox" />
                  <span>Hosting a book signing event</span>
                </div>
              </div>
            </div>
            <div className='col-12 col-md-6'>
              <input type="text" placeholder='Enter your full name' onChange={(e) => setFullName(e.target.value)} className='form-control' />
            </div>
            <div className='col-12 col-md-6'>
              <input type="email" placeholder='Enter your email' onChange={(e) => setEmail(e.target.value)} className='form-control' />
            </div>
            <div className='col-12'>
              <textarea rows="5" placeholder='Enter your message here...' onChange={(e) => setMessage(e.target.value)} className='form-control' />
            </div>
            <div className='col-12'>
              <a className='btn' onClick={submitHandler}>Submit Message</a>
              {/* <a className='btn' onClick={submitHandler} href={`mailto:transbeingx@gmail.com?subject=${`Name: ${fullName}, email: ${email}`}&body=${message}`}>Submit Message</a> */}
            </div>
          </div>
        </div>
        <div className='rightSection col-12 col-md-4'>
          <h6>Let me know your thoughts</h6>
          <p>I want to hear from you. How can I help?</p>
        </div>
      </div>
    </div >
  )
}

export default ContactUs
