import React from 'react'
import "./BlogCard.css"

const BlogCard = ({ blog }) => {
    console.log(blog)
    return (
        <div className='BlogCard'>
            <img src={blog?.image} alt="Blog" />
            <div className='date'> Apr 08, 2016</div>
            <h1>{blog?.title}</h1>
            <p>{blog?.description}</p>
            <div className='reaction'>
                <div>
                    <i className="fa fa-thumbs-up"></i>
                    <span>{blog.likes} Likes</span>
                </div>
                <div>
                    <i className="fa fa-comment"></i>
                    <span>{blog?.comments} Comments</span>
                </div>
            </div>
        </div>
    )
}

export default BlogCard
