import React from 'react'
import "./Sidebar.css"
import Logo from "../../assets/TBX-Logo-Grey-Blk.png"
import { useLocation } from 'react-router-dom'

const Sidebar = ({ toggleDrawer }) => {
    const location = useLocation();
    const hash = location.hash;

    const menuItems = ["home", "about", "events", "blog", "contact",]

    return (
        <div className='Sidebar'>
            <div>
                <div className='top'>
                    <img src={Logo} alt="Logo" />
                    {/* <h5><span>TransBeing</span> Experience</h5> */}
                </div>
                <ul>
                    {
                        menuItems?.map(item => {
                            return (
                                <li>
                                    <a onClick={toggleDrawer} href={`#${item}`} className={hash === `#${item}` && 'activeLink'}>{item}</a>
                                </li>
                            )
                        })
                    }
                </ul>
                <div className='socialMedia'>
                    <p>Follow Us:</p>
                    <div>
                        <a href='https://www.facebook.com/' target="_blank" rel="noreferrer">
                            <i className="fa-brands fa-facebook-f hoverprimary"></i>
                        </a>
                        <a href='https://www.youtube.com/' target="_blank" rel="noreferrer">
                            <i className="fa-brands fa-youtube hoverred"></i>
                        </a>
                        <a href='https://www.instagram.com/' target="_blank" rel="noreferrer">
                            <i className="fa-brands fa-instagram hoverpink"></i>
                        </a>
                        <a href='https://twitter.com/' target="_blank" rel="noreferrer">
                            <i className="fa-brands fa-twitter hoverinfo"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sidebar
