import React from 'react'
import "./Events.css"

const Events = () => {
  return (
    <div className='Events' id='events'>
      <div className='eventHeader'>
        <h2>Events</h2>
        <p>The latest updates and schedule of events.</p>
      </div>
      <div className="tableCon table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Event Description</th>
              <th scope="col">Status</th>
              <th scope="col">Progress</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Complete the final draft of TransBeing Experience Manuscript</td>
              <td>In-progress</td>
              <td>85%</td>
            </tr>
            <tr>
              <td>Establish funding for book publishing and marketing</td>
              <td>Initiated</td>
              <td>5%</td>
            </tr>
            <tr>
              <td>Launch funding campaign for pre-sale of book and consultation</td>
              <td>Planned</td>
              <td>0%</td>
            </tr>
            <tr>
              <td>Complete publishing activities and start tde marketing campaign</td>
              <td>Planned</td>
              <td>0%</td>
            </tr>
            <tr>
              <td>Book released for sale</td>
              <td>Planned</td>
              <td>0%</td>
            </tr>
            <tr>
              <td>Publish Press Release for book signing locations and dates</td>
              <td>Planned</td>
              <td>0%</td>
            </tr>
          </tbody>
        </table>
      </div >
    </div >
  )
}

export default Events
