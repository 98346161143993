import axios from 'axios';
import React, { useState } from 'react'
import paperIcon from "../../assets/paperIcon.png"
import "./Footer.css"

const Footer = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const submitHandler = async () => {
        await axios.post("/save", { email, name }).then(res => {
            if (res.status === 200) {
                alert("Data saved successfully");
            } else {
                console.log("Error");
                alert("Data could not be saved")
            }
        })
    }

    return (
        <footer>
            <div className='top'>
                <div className='left'>
                    <div>
                        <div>
                            <img src={paperIcon} alt="Icon" width="50px" />
                        </div>
                        <div>
                            <h2>Join the mailing for updates and promotions.</h2>
                            {/* <p>You may qualify for discounts when you get a quote from us.</p> */}
                        </div>
                    </div>
                    <div className='row gy-1 mt-2'>
                        <div className='col-12 col-md-6'>
                            <input type="text" name='name' placeholder='Enter your full name' className='form-control' onChange={(e) => setName(e.target.value)} />
                        </div>
                        <div className='col-12 col-md-6'>
                            <input type="email" name='email' placeholder='Enter your email' className='form-control' onChange={(e) => setEmail(e.target.value)} />
                        </div>
                    </div>
                </div>
                <div>
                    <button onClick={submitHandler}>Subscribe</button>
                </div>
            </div>
            <div className='bottom'>
                <p>© TransBeing Experience. All Rights Reserved.</p>
            </div>
        </footer>
    )
}

export default Footer
