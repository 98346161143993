import React from 'react'
import image1 from "../../assets/img395.jpg"
import image2 from "../../assets/img396.jpg"
import image3 from "../../assets/img397.jpg"
import image4 from "../../assets/img398.jpg"
import BlogCard from '../BlogCard/BlogCard'
import "./Blog.css"

const Blog = () => {
  const blogs = [
    {
      "image": image1,
      "date": "September 12, 2023",
      "title": "Gender Affirming Surgery – An Experimental Money-Maker?",
      "description": "Coming soon!",
      "likes": "21",
      "comments": "4"
    },
    {
      "image": image2,
      "date": "September 12, 2023",
      "title": "The New Leaders That Awaken and Transform Social Consciousness",
      "description": "Coming soon!",
      "likes": "68",
      "comments": "23"
    },
    {
      "image": image3,
      "date": "September 12, 2023",
      "title": "The Future of Gender-Affirming Hormone Therapy",
      "description": "Coming soon!",
      "likes": "21",
      "comments": "4"
    }
    // {
    //   "image": image4,
    //   "date": "September 12, 2023",
    //   "title": "How to Craft the Perfect Playlist for Productivity",
    //   "description": "Coming soon!",
    //   "likes": "68",
    //   "comments": "23"
    // }
  ]


  return (
    <div className='Blog' id='blog'>
      <div className='blogHeader'>
        <h2>Fresh From The Blog</h2>
        <p>Read our latest news and events.</p>
      </div>
      <div className='blogs row'>
        {
          blogs?.map(blog => {
            return (
              <div className='col-12 col-md-6 col-lg-4'>
                <BlogCard blog={blog} />
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default Blog
